<template>
    <div class="x-page-container" ref="resize">
        <div class="page-tips">
            <p>滞销商品(近30日内销量为0的商品)</p>
        </div>
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="货号">
                <el-input v-model="huoHaoValue" size="small" @change="huoHaoChange"/>
            </x-search-item>
            <x-search-item label="商品名称">
                <el-input v-model="search.shangPinMC" size="small"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
                <excel-export :tableData="exportTableData" :headAndKey="headAndKey" :isSum="true" :condition="condition"
                              fileName="滞销商品表">
                    <el-button type="success" size="mini" style="margin-left: 10px">导出</el-button>
                </excel-export>
            </div>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column prop="shangPinMC" label="商品名称"/>
            <el-table-column label="货号">
                <template slot-scope="scope">
                    <span v-if="scope.row.shiFuDuoGuiGe===dictYes">
                        {{scope.row.guiGeHuoHao}}
                    </span>
                    <span v-else>
                        {{scope.row.shangPinHuoHao}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="条码">
                <template slot-scope="scope">
                    <span v-if="scope.row.shiFuDuoGuiGe===dictYes">
                        {{scope.row.guiGeTiaoMa}}
                    </span>
                    <span v-else>
                        {{scope.row.shangPinTiaoMa}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="商品规格">
                <template slot-scope="scope">
                    <span v-if="scope.row.shiFuDuoGuiGe===dictYes">
                        {{scope.row.ciJiGuiGeMC}}
                    </span>
                    <span v-else>
                        {{dictWu}}
                    </span>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
    </div>
</template>
<script>
    import * as service from "@/service/spgl/ShangPinXX";
    import XTableBase from "@/components/x/XTableBase";
    import {DICT_YES_OR_NO,DICT_SORT} from "@/util/constant"
    import {deepCopy} from "@/util/objects";

    export default {
        name: "ZhiXiaoShangPinList",
        mixins: [XTableBase],
        components: {},
        data() {
            this.refreshService = service.getZhiXiaoList;
            this.service = service;
            return {
                search: {
                    shangPinHuoHao: '',
                    guiGeHuoHao:'',
                    shangPinMC:'',
                },
                huoHaoValue:'',
                dictYes:DICT_YES_OR_NO.YES.key,
                dictWu:DICT_SORT.NO.name,
                headAndKey:{
                    shangPinHuoHao:"货号",
                    shangPinTiaoMa:"条码",
                    shangPinMC:"商品名称",
                    ciJiGuiGeMC:"商品规格",
                },
                condition: [],
                exportTableData:[]
            }
        },
        methods:{
            refresh() { //默认刷新列表方法
                this.table.loading = true;
                return this.refreshService(Object.assign(this.search, this.searchNoClear)).then((response) => {
                    let ret = response.data;
                    this.table.data = ret.records;
                    if(this.table.data && this.table.data.length>0){
                        this.exportTableData = deepCopy(this.table.data)
                    }
                    this.exportTableData.forEach(item=>{
                        if(item.shiFuDuoGuiGe===this.dictYes){
                            item.shangPinHuoHao = item.guiGeHuoHao
                            item.shangPinTiaoMa = item.guiGeTiaoMa
                        }else{
                            item.ciJiGuiGeMC = this.dictWu
                        }
                    })
                    this.table.total = ret.total || 0;
                    //处理11条数据删除一条不显示问题
                    if (this.searchNoClear.current !== 1 && (!ret.records || ret.records === 0)) {
                        this.searchNoClear.current--
                        this.refresh()
                        return
                    }
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
            huoHaoChange(val){
                this.search.shangPinHuoHao = val
                this.search.guiGeHuoHao = val
            },
            handleClear(){
                Object.keys(this.search).forEach((key) => this.search[key] = '')
                this.huoHaoValue = ''
            }
        }
    }
</script>

<style scoped>
    .page-tips {
        padding: 8px 16px;
        background-color: #ecf8ff;
        border-radius: 4px;
        border-left: 5px solid #409EFF;
        font-size: 13px;
        color: #5e6d82;
        line-height: 1.5em;
        margin-bottom: 10px;
    }
</style>
